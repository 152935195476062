.header { 
    box-sizing: border-box;
    background: #FAFAFA;
    padding: 2.3vh;

    border: 0.2vh solid rgba(0, 0, 0, 0.1);
    border-radius: 1.3vh;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18.1vw;
}


.menuText { 
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2vh;
    line-height: 100%;
    margin-left: 2vw;
    color: #131517;
}

.logo {
    margin-right: 2.75vw;
    width: 8vw;
}

.mainMenu {
    width: 18.1vw;

    margin-top: 1vh;
    margin-left: 1vw;
    
    background: #FAFAFA;

    border: 0.2vh solid rgba(0, 0, 0, 0.1);
    border-radius: 1.5vh;
}

.text {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vh;
    line-height: 150%;

    color: #131517;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-left: 1vw;
    margin-right: 2vw;
}

.boldText {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2vh;
    line-height: 100%;
    opacity: 80%;

    color: #131517;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1vw;
    margin-bottom: 1vh;
    margin-top: 25vh;

}

.barText {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2vh;
    line-height: 100%;
    opacity: 80%;

    color: #131517;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1vw;
    margin-bottom: 2vh;
}

.miniMenuBar { 
    background: #FAFAFA;
    color: #000000;

    padding: 2vh;

    border: 0.2vh solid rgba(0, 0, 0, 0.1);
    border-radius: 1.3vh;

    margin-top: 1vh;
    margin-left: 1vw;

    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 2vh;

    color: #000000;
    
}

.tochMenuBar { 
    display: flex;

    background: #000000;
    color: #FAFAFA;

    padding: 2vh;

    border: 0.2vh solid rgba(0, 0, 0, 0.1);
    border-radius: 1.3vh;

    margin-top: 1vh;
    margin-left: 1vw;

    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 2vh;
}

.toch {
    margin-left: 7vw;
    width: 1.5vw;
}

.text {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    transition: opacity 0.3s ease, height 0.3s ease, padding 0.3s ease;
  }
  
  /* This is the state when the description is visible */
  .text.visible {
    opacity: 1;
    height: auto;
    padding: 1vw 0;
    margin-left: 1vw;
    margin-right: 1vw;
  }
  