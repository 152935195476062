.Content {
    position: relative;
    width: 150vw;
    height: 91vh;
    border-radius: 1vw;
    overflow: hidden;
    margin-top: 1vh;
    margin-left: 0.5vw;
    margin-right: 1vw;
  }
  
  .video-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .mute-button {
    position: absolute;
    top: 0.5vh;
    right: 0.5vw;
    background: rgba(0, 0, 0, 0.1);
    border: none;
    padding: 0.5vw;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  
  .mute-button:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  